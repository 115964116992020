<template>
  <div class="page-mask-broadcast-wrapper">
    <p class="mask-content">
      {{ msgErr }}
    </p>
    <el-button
      type="primary"
      class="mask-btn"
      @click="handleClick"
    >
      返回首页
    </el-button>
  </div>
</template>
<script>
export default {
  props: {
    msg: {
      type: Error,
      default: '',
    },
  },
  computed: {
    msgErr() {
      const msg = (`${this.msg}`).split(', ');
      return msg.length === 2 ? msg[1] : '后台出错，请联系管理员';
    },
  },
  methods: {
    handleClick() {
      this.$router.push('/home');
    },
  },
};
</script>
<style lang="scss" scoped>
.page-mask-broadcast-wrapper {
  width: 100%;
  height: calc(100vh - 60px);
  background-image: url('../../assets/img/pic@2x.png');
  text-align: center;
  margin: auto;
  //z-index: 4000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mask-content {
    text-align: center;
    opacity: 0.9;
    font-family: PingFangSC-Medium;
    font-size: 22px;
    color: #FCFCFC;
    line-height: 26px;
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
