<template>
  <div class="book-detail">
    <err-page-mask-broad-cast
      v-if="errMaskContent"
      :msg="errMaskContent"
    />
    <template v-else>
      <div class="head-group">
        <div style="position: relative;">
          <img
            class="head-image"
            :src="roomInfo.posterImgUrl"
          >
          <div class="img-text">
            <i class="iconfont icon-yuyue" />
            <span>预约中</span>
          </div>
          <div class="head-time">
            开播时间：{{ roomInfo.preStartTime | filterTime }}
          </div>
          <div class="title-group">
            <div
              v-if="roomInfo.experienceStatus"
              class="live-experience"
            >
              体验
            </div>
            <div class="title">
              {{ roomInfo.liveTheme }}
              <span class="title-type">{{ roomInfo.liveType | filterLiveType }}</span>
            </div>
            <el-popover
              v-if="!roomInfo.experienceStatus"
              v-sensor:click="{
                $element_id: 'live_share',
                consumer_id: profile.uid,
                room_id: roomId,
              }"
              placement="bottom"
              trigger="click"
              popper-class="share-popover"
              :visible-arrow="false"
              effect="dark"
            >
              <share
                :live-link="liveLink"
                :code-link="roomInfo.codeUrl"
              />
              <div
                slot="reference"
                class="share"
              >
                <el-tooltip
                  effect="dark"
                  placement="bottom"
                  content="分享"
                  :visible-arrow="false"
                  popper-class="share-tooltip"
                >
                  <i class="iconfont icon-fenxiangbeifen" />
                </el-tooltip>
              </div>
            </el-popover>
          </div>
          <div class="head-book">
            <i class="iconfont icon-v_hot" />
            <span>{{ roomInfo.subscribedNumber || 0 }} 人预约</span>
          </div>
        </div>
        <div class="live-info">
          <div class="count-down">
            <p v-if="overTime">
              直播即将开始
            </p>
            <p v-else>
              距离开播仅剩
            </p>
            <p
              v-if="overTime"
              class="text-time-over"
            >
              主播正在赶来的路上，请您耐心等待…
            </p>
            <time-down
              v-else
              :end-date="roomInfo.preStartTime"
              @timeUp="timeDown"
            />
            <el-button
              v-if="playSatisfy && roomInfo.liveRoomType==='SUBSCRIBED'"
              :class="overTimeSatisfy ? 'book-ed-btn': 'book-now-btn'"
              :disabled="overTimeSatisfy"
              type="primary"
              @click="playNow"
            >
              开始直播
            </el-button>
            <el-button
              v-else-if="roomInfo.subscribeType"
              class="book-ed-btn"
              disabled
            >
              已预约
            </el-button>
            <el-button
              v-else-if="roomInfo.subscribeType===0"
              v-sensor:click="{
                $element_id: 'live_appointing',
                consumer_id: profile.uid,
                room_id: roomId,
              }"
              class="book-now-btn"
              type="primary"
              @click="bookNow"
            >
              立即预约
            </el-button>
          </div>
          <div class="live-detail">
            <div class="detail-header">
              直播详情
            </div>
            <scroll-bar
              class="detail-body main-scroll"
              :flex-layout="true"
            >
              <div v-html="handleIntroduce(roomInfo.introduce)" />
            </scroll-bar>
          </div>
        </div>
      </div>
      <div class="info-group">
        <div v-if="roomInfo.guests && roomInfo.guests.length">
          <div class="info-title">
            Live嘉宾
          </div>
          <live-guest
            :guest="roomInfo.guests"
            :room-id="roomId"
            from-view="book"
          />
        </div>
        <div v-if="roomInfo.projectName && roomInfo.projectName.length">
          <div class="info-title">
            项目集
          </div>
          <div class="project-group">
            <template v-for="(item, index) in roomInfo.projectName">
              <div
                :key="index+'project'"
                class="project"
              >
                <img
                  v-if="item.companyLogo"
                  :src="item.companyLogo"
                  @click="goProject(item.companyId)"
                >
                <span
                  v-else
                  class="logo-default"
                  :style="{background: getRandomColor(item.companyId)}"
                >
                  {{ item.companyName && item.companyName[0] || '新' }}
                </span>
                <div class="project-info">
                  <div
                    class="project-name"
                    @click="goProject(item.companyId)"
                  >
                    {{ item.companyName }}<span
                      v-if="item.existBP"
                      class="project-bp"
                    >BP</span>
                  </div>
                  <div class="project-type">
                    {{ item.brief }}
                  </div>
                  <div>
                    <span
                      :key="'tag'+ index"
                      class="project-tag"
                    > {{ item.industryName }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-show="roomInfo.suscribedUsers && roomInfo.suscribedUsers.length">
          <div class="info-title">
            预约用户
          </div>
          <div class="book-group">
            <template v-for="(item, index) in roomInfo.suscribedUsers">
              <el-popover
                :key="index"
                placement="right-start"
                width="270"
                :visible-arrow="false"
                :value="item.showLastPop || false"
                trigger="manual"
                class="book-popover"
              >
                <option-user
                  :user="item"
                  :card-info="cardInfo"
                  from-view="replay"
                  :loading="opUserLoading"
                  @updateCardInfo="handleUpdateCardInfo"
                />
                <div
                  slot="reference"
                  class="book"
                  @click="handlerShowPopover(item, index)"
                >
                  <logo-panel
                    :info="item"
                    class="avatar"
                  />
                  <div class="book-name">
                    {{ item.name || '新用户' }}
                  </div>
                </div>
              </el-popover>
            </template>
          </div>
        </div>
      </div>
    </template>
    <qr-dialog
      :visible="bookModel"
      :start-time="roomInfo.preStartTime"
      @close="handleBookSuc"
    />
    <subscribe-tip-dialog
      :visible="SubscribeTipDialogVisible"
      :content="SubscribeTipDialogContent"
      :room-id="roomId"
      :show-footer="showFooter"
      :dialog-type="dialogType"
      @update:visible="toggleSubscribeTipDialog(false)"
    />
    <!-- 确定是否开始直播 -->
    <start-live-dialog
      :visible="startLiveDialogVisible"
      :live-room-id="roomId"
      @close="startLiveDialogVisible = false"
      @startSucess="handleStartLiveSuccess"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import LiveGuest from '@/views/components/LiveGuest';
import { subscribeLive, getLiveRoom } from '@/services/room';
import { getUserCardInfo } from '@/services/user';
import LogoPanel from '@/views/components/LogoPanel';
import ErrPageMaskBroadCast from '@/views/components/ErrPageMaskBroadCast';
import { environmentHref, cloudRequestPath } from '@/utils/cloudRequest';
import { getCloudToken } from '@/services/home';
import ScrollBar from '@/components/ScrollBar';
import { getRandomColor } from '@/utils/logoColors';
import StartLiveDialog from '@/views/home/StartLiveDialog.vue';
import TimeDown from './components/TimeDown';

export default {
  name: 'Index',
  filters: {
    filterTime(time) {
      return moment(time).format('YYYY年MM月DD日 HH:mm');
    },
    filterLiveType(type) {
      switch (type) {
      case 0:
        return '分享会';
      case 1:
        return '个人路演';
      case 2:
        return '路演活动';
      default:
        return '分享会';
      }
    },
  },
  components: {
    ErrPageMaskBroadCast,
    LiveGuest,
    TimeDown,
    LogoPanel,
    ScrollBar,
    Share: () => import('@/views/components/Share'),
    QrDialog: () => import('@/views/components/BookResult/QrDialog'),
    SubscribeTipDialog: () => import('@/views/home/SubscribeTipDialog.vue'),
    OptionUser: () => import('@/views/components/OptionUser'),
    StartLiveDialog,
  },
  data() {
    return {
      roomId: this.$route.params.id,
      roomInfo: {},
      liveLink: window.location.href,
      bookSucess: false,
      bookModel: false,
      msg: 0,
      overTime: false,
      SubscribeTipDialogVisible: false,
      SubscribeTipDialogContent: '',
      showFooter: false,
      dialogType: '',
      cardInfo: {},
      errMaskContent: '',
      opUserLoading: false,
      upDate: false,
      currPop: null,
      startLiveDialogVisible: false, // 是否开始直播dialog
      timeSatisfy: false,
      overTimeSatisfy: false, // 管理员超过20min为true
      timer: null,
    };
  },
  computed: {
    isBooked() {
      const { currentUser } = this.roomInfo;
      return (this.roomInfo.joinRoomType === 0 && currentUser?.joinType === 1) || this.bookSucess;
    },
    ...mapState('user', ['isLogin', 'profile']),
    playSatisfy() {
      return this.timeSatisfy && this.roomInfo.currentUser?.liveUserType === 0;
    },
  },
  created() {
    this.overTime = false;
    this.getLiveInfo();
  },
  mounted() {
    if (this.errMaskContent) {
      return;
    }
    const bookDetail = document.querySelector('.book-detail');
    if (bookDetail) {
      bookDetail.addEventListener('click', this.handleUserPopVisible);
    }
  },
  beforeDestroy() {
    const bookDetail = document.querySelector('.book-detail');
    if (bookDetail) {
      bookDetail.removeEventListener('click', this.handleUserPopVisible);
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    ...mapActions('login', [
      'showLoginDialog',
    ]),
    getRandomColor,
    handlerShowPopover(user, index) {
      // event.stopPropagation();
      if (this.roomInfo.suscribedUsers[index].showLastPop) {
        this.roomInfo.suscribedUsers[index].showLastPop = false;
        return;
      }
      if (this.currPop !== null) {
        this.roomInfo.suscribedUsers[this.currPop].showLastPop = false;
      }
      this.currPop = index;
      const { uid } = user;
      this.getCardInfo(uid);
      if (window.sensors) {
        sensors.track('$WebClick', {
          $element_id: 'live_to_personalpage',
          consumer_id: this.profile.uid,
          room_id: this.roomId,
        });
      }
    },
    async handleUpdateCardInfo(id) {
      this.upDate = true;
      await this.getCardInfo(id);
      this.upDate = false;
    },
    async getCardInfo(id) {
      this.opUserLoading = true;
      try {
        const cardInfo = await getUserCardInfo(id);
        this.cardInfo = {
          ...cardInfo,
          uid: id,
        };
        this.opUserLoading = false;
        if (this.upDate) return;
        this.roomInfo.suscribedUsers[this.currPop].showLastPop = true;
      } catch (error) {
        // console.log(error)
      }
    },
    handleUserPopVisible() {
      if (this.currPop !== null) {
        this.roomInfo.suscribedUsers[this.currPop].showLastPop = false;
        this.$forceUpdate();
      }
    },
    handleIntroduce(val) {
      if (!val) return;
      return val.replace(/\n/g, '<br>');
    },
    handleBookSuc() {
      this.bookModel = false;
      this.getLiveInfo();// 更新状态
    },
    bookNow() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      this.subscribeOperation(this.roomId);
    },
    async playNow() {
      if (!this.isLogin) {
        const toNext = () => {
          this.getLiveInfo();
        };
        this.showLoginDialog({
          callback: toNext,
        });
        return;
      }
      this.startLiveDialogVisible = true;
    },
    async subscribeOperation(roomId) {
      try {
        const res = await subscribeLive({
          id: roomId,
        });
        // 预约失败
        if (res?.code === 1) {
          this.showFooter = false;
          this.SubscribeTipDialogContent = res.msg;
          this.toggleSubscribeTipDialog(true);
          return;
        }
        // 进入直播间需要申请
        if (res?.code === 1001) {
          this.showFooter = true;
          this.SubscribeTipDialogContent = res.msg;
          this.dialogType = 'apply';
          this.toggleSubscribeTipDialog(true);
          return;
        }
        // 进入直播间需要认证创业者身份
        if (res?.code === 1002) {
          this.showFooter = true;
          this.SubscribeTipDialogContent = res.msg;
          this.dialogType = 'certify';
          this.toggleSubscribeTipDialog(true);
          return;
        }
        this.bookModel = true;
        await this.getLiveInfo();
      } catch (error) {
        // console.log(error)
      }
    },
    async getLiveInfo() {
      try {
        this.roomInfo = await getLiveRoom(this.roomId);
        const { liveRoomType } = this.roomInfo;
        const host = window.location.host;
        // 直播中
        if (liveRoomType === 'ONLINE' || liveRoomType === 1) {
          window.open(`https://${host}/#/liveroom/${this.roomId}`, '_self');
        }
        // 已结束
        if (liveRoomType === 'CLOSED' || liveRoomType === 3) {
          window.open(`https://${host}/#/replay/${this.roomId}`, '_self');
        }
        // 管理员开播倒计时
        if (this.roomInfo.currentUser?.liveUserType === 0) {
          this.handleTime(this.roomInfo.currentServerTimeMillis, this.roomInfo.preStartTime);
        }
      } catch (err) {
        this.errMaskContent = err;
      }
    },
    handleTime(currentServerTimeMillis, preStartTime) {
      let nowTime = currentServerTimeMillis;

      // 距离开播时间超过20min; 开播按钮置灰
      if (currentServerTimeMillis - preStartTime > 20 * 60 * 1000) {
        this.overTimeSatisfy = true;
      }
      // 还有1小时开播
      if (preStartTime - currentServerTimeMillis <= 3600 * 1000) {
        this.timeSatisfy = true;
      }

      this.$forceUpdate();
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        nowTime += 1000;

        // 距离开播时间超过20min; 开播按钮置灰
        if (nowTime - preStartTime > 20 * 60 * 1000) {
          this.overTimeSatisfy = true;
        }
        // 还有1小时开播
        if (preStartTime - nowTime <= 60 * 60 * 1000) {
          this.timeSatisfy = true;
        }
        this.$forceUpdate();
        if (this.overTimeSatisfy) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    toggleSubscribeTipDialog(visible) {
      this.SubscribeTipDialogVisible = visible;
    },
    timeDown() {
      this.overTime = true;
      this.getLiveInfo();
    },
    // 跳转个人版项目详情页
    async goProject(projectId) {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      const token = await getCloudToken();
      const fullPath = `${environmentHref}/#/insight/InsightProject/${projectId}`;
      window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`);
    },
    // 开始直播成功，刷新列表
    handleStartLiveSuccess() {
      this.getLiveInfo();
    },
  },
};
</script>

<style scoped lang="scss">
@import './index';
</style>
<style lang="scss">
.share-popover {
  width: 398px;
  height: 132px;
  padding: 16px;
  background: #2e313e;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  transform: translateY(-8px);
  border: 0;
}

.share-tooltip {
  transform: translate(0, -4px);
  background: #373946 !important;
  border-radius: 4px;
  width: 48px;
  height: 24px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  line-height: 24px;
  padding: 0;
}

.detail-body {
  /* 定义滑块 内阴影+圆角 */
  &::-webkit-scrollbar-track-piece {
    background: #303133;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3.5px;
  }
}
</style>
