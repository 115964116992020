<!-- 翻页效果 倒计时组件 -->
<template>
  <div
    class="vue-countdown-component"
  >
    <span class="time">{{ Day }}</span>
    <span class="text">天</span>
    <span class="time">{{ Hour }}</span>
    <span class="text">时</span>
    <span class="time">{{ Min }}</span>
    <span class="text">分</span>
    <span class="time">{{ Second }}</span>
    <span class="text">秒</span>
  </div>
</template>

<script>

export default {
  props: {
    endDate: { type: [Number, Date], default: 0 }, // 截止时间
  },
  data() {
    return {
      Day: '0',
      Hour: '00',
      Min: '00',
      Second: '00',
      timer: null,
    };
  },
  watch: {
    endDate(val) {
      this.countdown(val);
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    countdown(timestamp) {
      if (!timestamp) return;
      this.timer = setInterval(() => {
        const nowTime = new Date();
        const t = timestamp - nowTime.getTime();
        if (t > 0) {
          this.Day = Math.floor(t / 86400000);
          const hour = Math.floor((t / 3600000) % 24);
          const min = Math.floor((t / 60000) % 60);
          const sec = Math.floor((t / 1000) % 60);
          this.Hour = hour < 10 ? `0${hour}` : hour;
          this.Min = min < 10 ? `0${min}` : min;
          this.Second = sec < 10 ? `0${sec}` : sec;
        } else {
          clearInterval(this.timer);
          this.$emit('timeUp');
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.vue-countdown-component {
  font-size: 0;
  margin-top: 16px;
  .text {
    opacity: 0.8;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #FFFFFF;
    margin: 0 8px 0 4px;
    text-align: center;
    line-height: 18px;
  }
  .time {
    opacity: 0.9;
    font-family: DINAlternate-Bold;
    font-size: 32px;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
  }
}
</style>
