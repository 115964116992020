<template>
  <el-dialog
    :visible="visible"
    append-to-body
    width="468px"
    custom-class="launch-live-dialog"
    title="提示"
    @close="handleClose"
  >
    <div class="content-wrapper">
      您将开始直播，开播消息会发送给嘉宾和观众。
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="mini"
        @click="handleClose"
      >
        取消
      </el-button>
      <el-button
        type="primary"
        size="mini"
        @click="StartLive"
      >
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { startLive } from '@/services/user';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    liveRoomId: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    async StartLive() {
      try {
        await startLive({
          liveRoomId: this.liveRoomId,
        });
        const routes = this.$router.resolve({
          path: `/liveroom/${this.liveRoomId}`,
        });
        window.open(routes.href, '_blank');
      } finally {
        //
      }
      this.$emit('startSucess');
      this.handleClose();
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  padding: 22px 0;
  line-height: 20px;
}
</style>
